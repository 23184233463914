<template>
  <div class="row">
    <!-- <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" :color="color" loader="dots" /> -->
    <div class="col-12 f13">
      <div class="card concard card-shadow">
        <div class="card-header white text-center">
          <img src="@/assets/logo.png"
            style="width: 100px; border-radius: 50%;   box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;" />
          <div class="t1" style="margin: 5px">ลงทะเบียน ผู้ประกอบการ</div>
        </div>
        <Form @submit="onSubmitRegister" :validation-schema="register" id="kt_account_profile_details_form"
          ref="formContainer" novalidate="novalidate">
          <div class="card-body mb-47 mt-2 white">
            <!--begin::Card body-->
            <div class="card-body">
              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">คำนำหน้า</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field as="select" name="fn" class="form-control  form-control-solid" @change="checkFn($event)"
                    v-model="data_register.fn">
                    <option value="" selected disabled>โปรดเลือก</option>
                    <option value="นาย">นาย</option>
                    <option value="นาง">นาง</option>
                    <option value="นางสาว">นางสาว</option>
                    <option value="อื่นๆ">อื่นๆ</option>
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="fn" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row mt-1" v-if="fn_status">
                  <Field type="text" name="fn_other" class="form-control  form-control-solid" placeholder="คำนำหน้า"
                    v-model="data_register.fn_other" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="fn_other" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">ชื่อ-นามสกุล</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field type="text" name="full_name" class="form-control  form-control-solid" placeholder="ชื่อ-นามสกุล"
                    v-model="data_register.full_name" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="full_name" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>


              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">เบอร์โทรศัพท์</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field type="text" name="tel" class="form-control  form-control-solid" placeholder="เบอร์โทรศัพท์"
                    v-model="data_register.tel" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="tel" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>



             


            </div>
            <!--end::Card body-->
          </div>
          <div class="card-footer white">
            <div class="d-flex justify-center align-center">
              <button type="submit" class="button">ถัดไป</button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
// import useRegister from "@/service/api/register";
// import useSweetalert from "@/service/sweetalert2";
// import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
//import liff from "@line/liff";
import iconMale from "@/assets/icon/Male.png";
import iconFemale from "@/assets/icon/Female.png";
import { defineComponent, ref, onMounted, inject } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
export default defineComponent({
  name: "Register",
  components: {
    ErrorMessage,
    Field,
    Form,
    // Loading,
  },
  setup() {
    // const { Sconfirm, SconfirmNc, SToast } = useSweetalert();
    // const { Register } = useRegister();
    // let isLoading = ref(false);
    let color = ref(process.env.VUE_APP_COLOR);
    const store = inject("store");
    const router = inject("router");
    let fn_status = ref(false);
    let department_status = ref(false);
    const data_register = store.getters.getRegister;
    const phoneRegExp =
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const register = Yup.object().shape({
      fn: Yup.string().required("กรุณาเลือก คำนำหน้า").label("คำนำหน้า"),
      fn_other: Yup.string()
        .when("fn", {
          is: (fn) => fn === "อื่นๆ",
          then: Yup.string().required("กรุณากรอก คำนำหน้า"),
        })
        .label("คำนำหน้า"),
      full_name: Yup.string().required("กรุณากรอก ชื่อ-นามสกุล"),
      tel: Yup.string()
        .required("กรุณากรอกเบอร์โทรศัพท์")
        .matches(phoneRegExp, "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง")
        .min(10, "เบอร์โทรศัพท์ไม่ครบ")
        .max(10, "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง"),
    });

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const onSubmitRegister = (values) => {
      store.dispatch(SET_REGISTER, values);

      router.push({ name: "Register_2" });

      // Sconfirm("ยืนยัน การลงทะเบียน", "question").then(async (result) => {
      //   if (result.isConfirmed) {
      //     isLoading.value = true;

      //     await Register(store.getters.getRegister)
      //       .then((result) => {
      //         isLoading.value = false;
      //         if (result.data.status === "success") {
      //           SconfirmNc("ลงทะเบียนสำเร็จ", "success", "ตกลง").then(() => {
      //             if (store.getters.getUrlCallBack.id) {
      //               liff.ready.then(() => {
      //                 liff.openWindow({
      //                   url: store.getters.getUrlCallBack.id,
      //                   external: false,
      //                 });
      //               });
      //             } else {
      //               router.push({ name: "Home" });
      //             }
      //           });
      //         } else {
      //           SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(
      //             () => {
      //               liff.ready.then(() => {
      //                 liff.closeWindow();
      //               });
      //             }
      //           );
      //         }
      //       })
      //       .catch((error) => {
      //         if (error.response) {
      //           let errors = error.response.data.errors;

      //           if (errors.id_line) {
      //             SToast("error", `${errors.id_line[0]}`);
      //           } else {
      //             SToast("error", "เกิดข้อผิดผาด กรุณาลองใหม่อีกครั้ง");
      //           }
      //         }
      //       });
      //   }
      // });
    };


    function checkFn(event) {
      if (event.target.value === "อื่นๆ") {
        fn_status.value = true;
      } else {
        fn_status.value = false;
      }
    }

    function checkDepartment(event) {
      if (event.target.value === "อื่นๆ") {
        department_status.value = true;
      } else {
        department_status.value = false;
      }
    }

    return {
      onSubmitRegister,
      register,
      checkFn,
      fn_status,
      iconMale,
      iconFemale,
      data_register,
      checkDepartment,
      department_status,
      color
    };
  },
});
</script>

<style scoped>
.bdhd {
  border-bottom: 2px solid #dee2e6 !important;
}

.icon {
  width: 50px;
  padding: 5px;
}

.gender {
  padding: 30px 20px;
  border: 1px solid #dee2e6;
  border-radius: 10px;
}

input[type="radio"] {
  display: none;
}

input:checked+span {
  color: var(--color);
  border: 2px solid var(--color);
}
</style>
